import React from "react";

import API from "../Global/API";
import Model from "../Definitions/Model";
import Settings from "../Global/Settings";
import Property from "../Definitions/Property";
import Formatter from "../Global/Formatter";
import ModalButton from "../Components/ModalButton";
import PropertyMap from "../Definitions/PropertyMap";
import ImageWrapper from "../Components/ImageWrapper";
import EmployeeForm from "../Actions/EmployeeActions/EmployeeForm";
import EmployeeView from "../Pages/Admin/Employee/EmployeeView";
import EmployeeActions from "../Actions/EmployeeActions";
import EmployeeStatusChip from "../Pages/Admin/Employee/EmployeeStatusChip";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

/**
 * Employee model.
 *
 * @type {Object}
 */
const Employee = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'employee',


    /**
     * The appropriate API endpoint root.
     */
    route: 'employees',


    /**
     * Any additional query parameters.
     */
    query: {
        $filter: 'isActive eq {1}',
        $expand: 'specialty, state, status'
    },


    /**
     * A series of quick filters to apply to the index layout.
     */
    filters: [
        {
            label: 'Active',
            query: {
                $filter: 'isActive eq {1} and isDeleted eq {0}'
            },
        },{
            label: 'In Progress',
            query: {
                $filter: 'isActive eq {0} and isDeleted eq {0}'
            },
        },{
            label: 'Ready to Go',
            query: {
                $filter: 'statusId in {7} and isDeleted eq {0}'
            },
        },{
            label: 'Inactive',
            query: {
                $filter: 'isDeleted eq {1}'
            },
        },{
            label: 'All',
            query: {
                $filter: ''
            },
        }
    ],


    /**
     * The form component.
     *
     * @returns {*}
     * @param parameters
     */
    form: (parameters) => <EmployeeForm {...parameters}/>,


    /**
     * The menu action.
     *
     * @param parameters
     * @returns {*}
     */
    action: (parameters) => <EmployeeActions {...parameters}/>,


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'image',
        'lastName',
        'firstName',
        'specialtyId',
        'emailAddress',
        'phoneNumber',
        'statusId',
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'image',
            label: 'Image',
            width: '60px',
            loader: () => <Skeleton height={48} width={48}/>,
            render: record => (
                <ImageWrapper
                    src={API.getFilePath(record.image)}
                    width={48}
                    height={48}
                />
            ),
            hideLabel: true
        }),
        Property.create({
            key: 'lastName',
            width: '15%',
            label: 'Last Name',
            render: record => (
                <ModalButton
                    label={record.lastName}
                    component={'a'}
                    bodyStyle={{
                        paddingTop: 0
                    }}
                    children={
                        <EmployeeView id={record.id}/>
                    }
                />
            ),
            sortable: true
        }),
        Property.create({
            key: 'firstName',
            width: '15%',
            label: 'First Name',
            render: record => (
                <ModalButton
                    label={record.firstName}
                    component={'a'}
                    bodyStyle={{
                        paddingTop: 0
                    }}
                    children={
                        <EmployeeView id={record.id}/>
                    }
                />
            ),
            sortable: true
        }),
        Property.create({
            key: 'specialtyId',
            width: '17%',
            label: 'Specialty',
            render: record => record.specialty ? record.specialty.name : '',
            sortable: false,
            filterable: true,
            renderLabel: option => option.name,
            filterIsEqual: (option, value) => option.id === value.id,
            filterResults: async () => {
                return await API.get('specialties', {
                    $select: 'id,name',
                    $orderby: `name asc`
                });
            },
            defaultFilterOptions: async (value) => {
                return await API.get('specialties', {
                    $filter: `id in {${value.join(',')}}`
                })
            }
        }),
        Property.create({
            key: 'createdDate',
            label: 'Created At',
            render: record => Formatter.dateTime(record.createdDate),
            sortable: true
        }),
        Property.create({
            key: 'emailAddress',
            label: 'Email',
            width: '22%',
            render: record => <a href={`mailto:${record.emailAddress}`}>{record.emailAddress}</a>,
            sortable: true
        }),
        Property.create({
            key: 'stateId',
            label: 'State',
            render: record => record.state && record.state.id ? record.state.name : '',
            sortable: false,
        }),
        Property.create({
            key: 'helixId',
            label: 'True Helix',
            align: 'center',
            render: record => {
                if(!record.helixId){
                    return null;
                }

                return (
                    <Button
                        href={`${Settings.helixUrl}/Caregivers/${record.helixId}`}
                        target={'_blank'}
                        children={'View Record'}
                        component={'a'}
                        className={'white-space__pre'}
                        startIcon={<OpenInNewIcon/>}
                    />
                )
            },
            sortable: false,
        }),
        Property.create({
            key: 'phoneNumber',
            label: 'Phone',
            width: '17%',
            render: record => <a href={`tel:${record.phoneNumber}`}>{Formatter.phoneString(record.phoneNumber)}</a>,
            sortable: true
        }),
        Property.create({
            key: 'statusId',
            align: 'center',
            label: 'Status',
            render: record => <EmployeeStatusChip size={'medium'} record={record}/>,
            sortable: false,
            filterable: true,
            renderLabel: option => option.name,
            filterIsEqual: (option, value) => option.id === value.id,
            filterResults: async () => {
                return await API.get('statuses', {
                    $select: 'id,name',
                    $filter: 'modelType eq {Employee}',
                    $orderby: `name asc`
                });
            },
            defaultFilterOptions: async (value) => {
                return await API.get('statuses', {
                    $filter: `id in {${value.join(',')}}`
                })
            }
        }),
        Property.create({
            key: 'isActive',
            label: 'Active',
            render: record => !record.isDeleted ? 'Active' : 'Inactive',
            sortable: true,
            selectable: false,
            filterable: false,
            renderLabel: option => !!option ? 'Yes' : 'No',
        }),
        Property.create({
            key: 'inApp',
            label: 'In App',
            render: record => !record.inApp ? 'No' : 'Yes',
            sortable: true,
            selectable: true,
            filterable: true,
            renderLabel: option => !!option ? 'Yes' : 'No',
        }),
        Property.create({
            key: 'hasDirectDeposit',
            label: 'Direct Deposit',
            render: record => !record.hasDirectDeposit ? 'No' : 'Yes',
            sortable: true,
            selectable: true,
            filterable: true,
            renderLabel: option => !!option ? 'Yes' : 'No',
        }),
        Property.create({
            key: 'isHelixOptIn',
            label: 'Enrolled in Helix',
            render: record => !record.isHelixOptIn ? 'No' : 'Yes',
            sortable: true,
            selectable: true,
            filterable: true,
            renderLabel: option => !!option ? 'Yes' : 'No',
        }),
        Property.create({
            key: 'isInvited',
            label: 'Invited',
            render: record => record.isInvited ? 'Yes' : 'No',
            sortable: true,
            filterable: false,
            renderLabel: option => !!option ? 'Yes' : 'No',
        }),
        Property.create({
            key: 'payRate',
            label: 'Pay Rate',
            render: record => record.payRate ? Formatter.currency(record.payRate) : '',
            sortable: true,
        }),
        Property.create({
            key: 'payNotes',
            label: 'Pay Notes',
            render: record => <Box className={'white-space__pre'}>{record.payNotes}</Box>,
            sortable: true,
        })
    ])
};

export default Employee;